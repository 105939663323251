import React, {useState} from 'react';
import axios from 'axios';
import Layout from '../../components/layout';
import {Modal} from '../../components/modal';
import Button from '../../components/button';
import contactForm from '../../img/contact-form.png';

import './styles.scss';
import {isValidEmail, isValidDomain} from '../../requests';
import {closeIconWithBackground} from '../../img/svg-icons';

const ContactPage = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    isModalOpen: false,
    error: false,
    errorMessage: '',
  });

  const submitHandler = async e => {
    e.preventDefault();
    if (!isValidEmail(formData.email) && !isValidDomain(formData.email)) {
      setFormData({...formData, error: true, errorMessage: 'Please enter valid email address.'});
      return;
    }
    if (!formData.message.trim() || !formData.subject.trim() || !formData.name.trim()) {
      setFormData({...formData, error: true, errorMessage: 'Please fill out all the fields.'});
      return;
    }
    setLoading(true);
    const {data} = await axios({
      method: 'post',
      url: 'https://api.hexometer.com/v2/ql',
      data: {
        operationName: 'ContactFormSubmit',
        variables: {
          name: formData.name.trim(),
          email: formData.email.trim(),
          subject: `searchscraper: ${formData.subject.trim()}`,
          message: JSON.stringify(formData.message.trim()),
          emailTo: 'support@hexometer.com',
          productType: 'searchscraper',
        },
        query:
          'mutation ContactFormSubmit($name: String!, $email: String!, $subject: String!, $message: String!, $emailTo: String, $productType: String) {\n  contactFormSubmit(name: $name, email: $email, subject: $subject, message: $message, emailTo: $emailTo, productType: $productType) {\n    error\n    message\n    __typename\n  }\n}\n',
      },
    });
    setLoading(false);
    if (data && data.data && data.data.contactFormSubmit && data.data.contactFormSubmit.error) {
      setFormData({...formData, error: true, errorMessage: 'Something went wrong, please try again.'});
    } else {
      setFormData({
        ...formData,
        isModalOpen: !formData.isModalOpen,
        error: false,
        errorMessage: '',
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    }
  };

  const changeHandler = event => {
    const target = event.target;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const modalToggle = async () => {
    await setFormData({
      ...formData,
      isModalOpen: !formData.isModalOpen,
    });
    // toggleLastURL()
  };

  return (
    <Layout
      title={'Contact Customer Support | Search Scraper'}
      metaName={'description'}
      metaContent={
        'Contact our customer care support and get all the necessary information regarding our service. Search Scraper customer support will help you out!'
      }
      small
    >
      <Modal
        isOpen={formData.isModalOpen}
        modalToggle={modalToggle}
        title="Successfully sent!"
        // onButtonClick={modalToggle}
        // buttonText={'Close'}
        className=""
        headerCentered
      >
        <p className="text-center">Your message has been sent, we will come back to you shortly.</p>
      </Modal>
      <div className="contact-block d-flex">
        <div className="d-none d-md-flex">
          <img src={contactForm} alt="contact" className="m-auto" />
        </div>
        <div className="main container">
          <div className="page-hero">
            <h1 className="page-title text-left">Get in touch</h1>
          </div>
          <form className="contact_form_box" onSubmit={e => !loading && submitHandler(e)}>
            <div className="row">
              <div className="col-lg-6">
                <input
                  required
                  type="text"
                  className="input"
                  placeholder={'Your name'}
                  name="name"
                  value={formData.name}
                  onChange={changeHandler}
                />
              </div>
              <div className="col-lg-6">
                <input
                  required
                  type="text"
                  className="input"
                  placeholder={'Your email'}
                  name="email"
                  value={formData.email}
                  onChange={changeHandler}
                />
              </div>
              <div className="col-lg-12">
                <input
                  required
                  type="text"
                  className="input"
                  placeholder={'Your subject'}
                  name="subject"
                  value={formData.subject}
                  onChange={changeHandler}
                />
              </div>
              <div className="col-lg-12">
                <textarea
                  required
                  className="textarea"
                  rows={5}
                  onChange={changeHandler}
                  name="message"
                  placeholder={'Type your message here'}
                  value={formData.message}
                />
              </div>
              <div className="col-lg-12 footer-block">
                <Button onClick={submitHandler} aboutVideo className="" disabled={loading} loading={loading}>
                  Send
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {formData.errorMessage && (
        <Modal
          isOpen={formData.errorMessage}
          modalToggle={() => setFormData({...formData, error: false, errorMessage: ''})}
          title=""
          className=""
          headerCentered
        >
          <div className="text-center mb-5 modal-msg-icon">{closeIconWithBackground}</div>
          <p className="text-center">{formData.errorMessage}</p>
        </Modal>
      )}
    </Layout>
  );
};

export default ContactPage;
